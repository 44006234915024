import React, { Suspense, useLayoutEffect } from 'react';
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';

// Pages
const Home = React.lazy(() => import('./pages/Home/index.page'));

// @ts-ignore
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
});

const App = () => {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <ScrollToTop>
          <Route exact path="/" component={Home} />
        </ScrollToTop>
      </Suspense>
    </Router>
  );
};

export default App;
